import dynamic from "next/dynamic"
import useTranslation from "next-translate/useTranslation"
import { FC, useEffect } from "react"
import { useInView } from "react-intersection-observer"

import { AddressAutocomplete } from "@app/shared/components/AddressAutocomplete"
import { MyImage } from "@app/shared/components/MyImage"
import { Typography } from "@app/shared/components/Typography"

import {
  homeHero,
  homeHeroAnimationDesktop,
  homeHeroAnimationMobile,
  homeHeroContent,
  homeHeroContentSubtitle,
  homeHeroContentTitle,
  homeHeroImageDesktop,
  homeHeroImageDesktopAbTest,
  homeHeroImageMobile,
  homeHeroImageMobileAbTest,
  homeHeroImageWrapperDesktop,
  homeHeroImageWrapperMobile,
  homeHeroVideoDesktop,
  homeHeroVideoDesktopAbTest,
  homeHeroVideoMobile,
} from "./styles.css"

const LottiePlayer = dynamic(() => import("@app/shared/components/LottiePlayer"))

const abTestHeroes = ["control", "data", "convenience", "trackrecord"] as const
export type AbTestHeroes = typeof abTestHeroes[number]

export type HomeHeroProps = {
  abTestHero?: AbTestHeroes
  updateVisibility: (visibility: boolean) => void
}

type HeroConfig = {
  heroImage: string
  heroImageMobile: string
  title: string
}

export const HomeHero: FC<HomeHeroProps> = ({ abTestHero = "control", updateVisibility }) => {
  const { inView, ref } = useInView({
    initialInView: true,
  })

  useEffect(() => {
    updateVisibility(inView)
  }, [inView, updateVisibility])

  // revert all this stuff when ABCD test is over https://casavo.atlassian.net/browse/ACQS-224
  const heroConfig: Record<AbTestHeroes, HeroConfig> = {
    control: {
      heroImage: "/images/home/hero.webp",
      heroImageMobile: "/images/home/hero-mobile.webp",
      title: "sell:title",
    },
    convenience: {
      heroImage: "/images/home-hero/hero-convenience.webp",
      heroImageMobile: "/images/home-hero/hero-convenience-mobile.webp",
      title: "sell:herotest_landing_convenience_headline",
    },
    data: {
      heroImage: "/images/home-hero/hero-data.webp",
      heroImageMobile: "/images/home-hero/hero-data-mobile.webp",
      title: "sell:herotest_landing_data_headline",
    },
    trackrecord: {
      heroImage: "/images/home-hero/hero-trackrecord.webp",
      heroImageMobile: "/images/home-hero/hero-trackrecord-mobile.webp",
      title: "sell:herotest_landing_trackrecord_headline",
    },
  }

  const { heroImage, heroImageMobile, title } = heroConfig[abTestHero] || heroConfig.control

  const isControlPage = abTestHero === "control"

  const { t } = useTranslation("sell")
  return (
    <section ref={ref} className={homeHero} id="hero-section">
      <div className={homeHeroContent}>
        <Typography className={homeHeroContentTitle} component="h1" variant="display">
          {t(title)}
        </Typography>
        <Typography className={homeHeroContentSubtitle} component="h2" variant="h4">
          {t("sell:subtitle")}
        </Typography>
        <AddressAutocomplete />
      </div>

      {isControlPage ? (
        <>
          <div className={homeHeroVideoMobile}>
            <div className={homeHeroImageWrapperMobile}>
              <MyImage
                alt="hero"
                className={homeHeroImageMobile}
                height={376}
                loading="lazy"
                src={heroImageMobile}
                width={700}
              />
            </div>
            <LottiePlayer loop className={homeHeroAnimationMobile} name="home/hp_buyers_m" />
          </div>

          <div className={homeHeroVideoDesktop}>
            <div className={homeHeroImageWrapperDesktop}>
              <MyImage
                alt="hero"
                className={homeHeroImageDesktop}
                height={376}
                loading="lazy"
                src={heroImage}
                width={700}
              />
            </div>
            <LottiePlayer className={homeHeroAnimationDesktop} loop={false} name="home/hp_buyers_d" />
          </div>
        </>
      ) : (
        <>
          <div className={homeHeroVideoMobile}>
            <MyImage
              alt="hero"
              className={homeHeroImageMobileAbTest}
              height={376}
              loading="lazy"
              src={heroImageMobile}
              width={700}
            />
          </div>

          <div className={homeHeroVideoDesktopAbTest}>
            <MyImage
              alt="hero"
              className={homeHeroImageDesktopAbTest}
              height={376}
              loading="lazy"
              src={heroImage}
              width={700}
            />
          </div>
        </>
      )}
    </section>
  )
}
