import { motion } from "framer-motion"

import { useBgColor } from "@app/shared/utils/context/useBgColor"

import { Benefits } from "./Benefits"
import { Faq } from "./Faq"
import { GoogleReviews } from "./GoogleReviews"
import { AbTestHeroes, HomeHero } from "./Hero"
import { HomePress } from "./Press"
import { Steps } from "./Steps"
import { homeSeparator } from "./styles.css"
import { ValuePropositionSection } from "./ValueProposition"

type NewHomePageProps = {
  abTestHero?: AbTestHeroes
  updateHeroVisibility: (visibility: boolean) => void
}
export const NewHomePage: React.FC<NewHomePageProps> = ({ abTestHero, updateHeroVisibility }) => {
  const { bgColor } = useBgColor()
  return (
    <motion.div style={{ backgroundColor: bgColor, transition: "background-color .6s ease-in-out" }}>
      <HomeHero abTestHero={abTestHero} updateVisibility={updateHeroVisibility} />
      <hr className={homeSeparator} />
      <Steps />
      <ValuePropositionSection />
      <Benefits />
      <Faq />
      <GoogleReviews />
      <HomePress />
    </motion.div>
  )
}
